import validateNL from 'vee-validate/dist/locale/nl.json';
import { mergeWithBrandLangFile } from './helpers';

const original = {
  /**
   * 'common' rules:
   * - Must be generic words.
   * - The key must be written in singular adding the plural into the value.
   * - The key must be written in camelCase.
   * - The value must be written in lower case unless it's a name.
   */
  common: {
    ascending: 'oplopend',
    descending: 'aflopend',
    description: 'beschrijving',
    hello: 'hallo',
    hi: 'hi',
    eur: '€',
    kg: 'kg',
    share: 'obligatie | obligaties',
    tree: 'boom | bomen',
    dividend: 'dividend | dividends',
    myDividend: 'Mijn dividend | Mijn dividend',
    myInterestRate: 'Mijn uitgekeerde betalingen',
    prospectus: 'prospectus',
    investment: 'investering | investeringen',
    interest: 'rendement',
    earning: 'uitgekeerde betalingen',
    fund: 'project | projecten',
    invest: 'investeer',
    investIn: 'investeer in dit project',
    investMore: 'meer investeren',
    investNow: 'investeer nu',
    amount: 'hoeveelheid',
    total: 'totaal',
    pay: 'betaling',
    payment: 'betaling | betalingen',
    id: 'id',
    serialNumber: 'serial nummer',
    name: 'Voornaam',
    firstName: 'Voornaam',
    surname: 'Achternaam',
    email: 'e-mail | e-mails',
    e_mail: 'e-mail',
    password: 'wachtwoord | wachtwoorden',
    save: 'opslaan',
    tier: 'tier',
    confirm: 'bevestig',
    activate: 'activeren',
    eIDAS: 'eIDAS',
    gDPR: 'GDPR',
    iDIN: 'iDIN',
    and: 'en',
    dashboard: 'dashboard',
    myDashboard: 'Mijn dashboard',
    backToCorekees: 'Terug naar corekees.com',
    referAFriend: 'Een vriend(in) aanbevelen',
    back: 'terug',
    sold: 'verkocht',
    participation: 'minimale investering',
    available: 'beschikbaar',
    download: 'download | downloads',
    brochure: 'brochure',
    identification: 'identificatie',
    summary: 'samenvatting',
    status: 'status',
    process: 'start | processen',
    processing: 'processing',
    error: 'error',
    success: 'gelukt',
    paid: 'betaald',
    fail: 'fout',
    failed: 'mislukt',
    canceled: 'geannuleerd',
    cancel: 'annuleren',
    expired: 'verlopen',
    open: 'open',
    step: 'stap | stappen',
    fullName: 'volledige naam',
    telephone: 'telefoon',
    companyName: 'bedrijfsnaam',
    phone: 'telefoon',
    dateOfBirth: 'geboortedatum',
    bankAccount: 'bankrekening',
    sendDataChangeRequest: 'Verstuur data-wijzigings verzoek',
    dataChangeRequestPending: 'Data wijzigings verzoek is ingediend',
    dataChangeRequestValue: 'Aangevraagde waarde',
    iban: 'IBAN',
    city: 'plaats',
    country: 'land',
    address: 'adres',
    houseNumber: 'huisnummer',
    number: 'nummer',
    postalCode: 'postcode',
    welcome: 'welkom Dhr./Mevr.',
    availability: 'beschikbaarheid',
    euro: 'euro | euros',
    euroSymbol: '€',
    contact: 'contact',
    countryOfBirth: 'land van herkomst',
    additionalInfo: 'overige info',
    or: 'of',
    from: 'vanaf',
    to: 'naar',
    for: 'voor',
    support: 'support',
    subject: 'onderwerp',
    message: 'bericht',
    login: 'log in',
    register: 'registreren',
    reset: 'reset',
    resetPass: 'reset wachtwoord',
    setPass: 'kies uw wachtwoord',
    secondFactor: '2-factor authenticatie',
    revertSecondFactor: '2-factor authenticatie annuleren',
    nationality: 'nationaliteit',
    realEstate: 'vastgoed index',
    aex: 'aex',
    vs: 'vs',
    property: 'property',
    warning: 'waarschuwing',
    document: 'document | documenten',
    location: 'locatie | locaties',
    floorplan: 'plattegrond',
    benefit: 'voordeel | voordelen',
    company: 'bedrijf | bedrijven',
    setting: 'instelling | instellingen',
    logout: 'log uit',
    help: 'help',
    optional: 'optioneel',
    source: 'bron',
    portfolio: 'portfolio',
    fundsLink: 'project | projecten',
    knowledge: 'kennisbank',
    glossary: 'woordenlijst',
    referral: 'doorverwijzing',
    cookie: 'cookie | cookies',
    no: 'nee',
    yes: 'ja, dit is oke',
    loadMore: 'meer projecten tonen',
    loadLess: 'minder projecten tonen',
    verified: 'account geverifieerd',
    pending: 'account wordt geverifieerd',
    rejected: 'account niet geaccepteerd',
    notIdentified: 'account niet gevonden',
    year: 'jaar',
    yearInitials: 'j',
    perYear: 'per jaar',
    perYearShort: 'p/jr',
    myAccount: 'mijn account',
    my: 'mijn',
    details: 'gegevens',
    na: 'n.v.t.',
    here: 'hier',
    send: 'verstuur',
    bond: 'obligatie | obligaties',
    month: 'maand | maanden',
    day: 'dag | dagen',
    week: 'week | weken',
    date: 'datum',
    orderDate: 'bestel datum',
    endDate: 'eind datum',
    yearly: 'per jaar',
    monthly: 'maandelijks',
    perMonth: 'per maand',
    new: 'nieuwe',
    search: 'zoeken',
    next: 'volgende',
    prev: 'vorige',
    fundCompleted: 'volgeschreven',
    comingSoon: 'binnenkort beschikbaar',
    downloadsComingSoon: 'binnenkort te downloaden',
    maxInvestmentsReached: 'maximaal geïnvesteerd',
    incorrectPassword: 'verkeerd wachtwoord',
    autoLogOut: 'U bent automatisch uitgelogd als gevolg van langdurige inactiviteit',
    investor: 'investeerder | investeerders',
    cookiePolicy: 'Cookie policy',
    copyright: 'Bloqhouse 2019',
    accountDisabled: 'Uw account is gedeactiveerd.',
    contactSupport: 'Neem contact op met {nameEmail} voor ondersteuning.',
    supportQuestion: 'Waar kunnen we je mee helpen? Stel hier je vraag:',
    websiteMaintenance: 'Website gesloten voor onderhoud.',
    closeModal: 'Sluit venster',
    paidOnline: 'Online betaling',
    paidOffline: 'Offline betaling',
    dutch: 'Nederlands',
    english: 'Engels',
    gender: 'aanhef',
    male: 'meneer',
    female: 'mevrouw',
    decline: 'Weigeren',
    accept: 'Accepteer',
    disabled: 'Deze selectie kan niet worden gewijzigd',
    more: 'meer',
    emission: 'uitstoot',
    myCompensation: 'mijn compensatie',
    myCo2Compensation: 'mijn CO₂ compensatie',
    co2Compensation: 'CO₂ compensatie',
    compensationProgressBarDetail: 'Jaarlijkse CO₂ uitstoot gemiddelde Nederlander',
    remaining: 'resterend',
    treeNumber: 'aantal obligaties',
    treeAge: 'jouw oudste obligatie is {age} oud',
    expectedNextPayment: 'verwachte volgende uitbetaling',
    progressOfInvestment: 'voortgang van investering',
    emissions: 'uitstoot',
    compensated: 'gecompenseerd',
    achievedROI: 'voltooide ROI',
    expectedInterestROI: 'verwachte rente',
    expectedRepaymentROI: 'verwachte aflossing',
    moreDetails: 'meer details',
    lessDetails: 'minder details',
    duration: 'looptijd',
    subscription: 'abonnement',
    'one-off': 'eenmalig',
    typeOfInvestment: 'type investering',
    'gift-purchase': 'cadeau aankoop',
    'gift-redeem': 'cadeau ontvangen',
    'start subscription': 'start abonnement',
    redeemGiftCode: 'cadeaucode verzilveren',
    redeem: 'verzilveren',
    giftCode: 'cadeaucode',
  },
  validations: {
    ...validateNL.messages,
    alpha_num: 'Dit veld mag alleen letters en nummers bevatten',
    alpha_spaces: 'Dit veld mag alleen letters en spaties bevatten',
    confirmed: 'Dit veld komt niet overeen met eerder gegeven input',
    email: 'Dit veld moet een geldig e-mailadres bevatten',
    integer: 'Dit veld moet moet een nummer zijn',
    max: 'Dit veld mag niet groter zijn dan {length} karakters',
    min: 'Dit veld moet minimaal {length} karakters zijn',
    ext: 'Bestand moet van het type {types} zijn',
    required: 'Dit veld is verplicht',
    multiplier: {
      payment: 'Het bedrag van de inleg moet een meervoud zijn van {number}€',
    },
  },
  /**
   * Specific section rules:
   * - Never use individual common words (you have the 'common' section for that).
   * - Never use dots.
   * - HTML tags like <a> inside a block of text must split it into two; one, the plain text, and two, the text inside the tag.
   * - No plurals here.
   */
  landing: {
    header: {
      accessibleAndUnderstandable: 'Snel en toegankelijk investeren in vastgoed',
      applyNowInvestor: 'Registreer nu en wordt vastgoedinvesteerder!',
      exploreProperties: 'Bekijk het vastgoed',
      registerNow: 'Registreer nu',
      explorePropertiesBtn: 'Begin met verkennen',
    },
    accessible: {
      accessibleAndTransparent: 'Toegankelijk en transparant',
      weMakeItVeryEasy: 'Het is de missie van Bloqhouse om investeren in vastgoed transparant en toegankelijk te maken',
      hasManyYears: 'Gedeeld eigendom in vastgoed maakt het mogelijk te investeren zonder daarmee tijd en moeite in beheer te steken',
      youCanStartBuying: 'Na het kiezen van één van de objecten op het platform kan je binnen enkele minuten investeren',
      onceTheSaleIsCompleted: `Jouw identiteit wordt geverifieerd via iDIN en obligaties zijn te koop vanaf €100 tot €100.000 via iDEAL
        (obligaties boven €100.000 gaan via SEPA banktransactie)`,
      startBuying: 'Koop vastgoed obligaties vanaf €100',
      receivedMonth: 'Ontvang dividend per maand in Euro’s',
      tradeYourShares: 'Verkoop je obligaties via het handelsplatform (lancering eind 2019)',
    },
    fund: {
      emissionPrice: 'Prijs per obligatie',
      totalFundSize: 'Totale project grootte',
      seeDetails: 'Meer info',
    },
    counters: {
      emissionCosts: 'Emissie kosten',
      grossDividendYield: 'Bruto rente',
      netDividendYield: 'Rente',
      dividendsPerYear: 'Dividend per jaar',
      occupancyRate: 'Bezettingsgraad',
    },
    benefits: {
      exploreProperties: 'Bekijk het vastgoed',
      tab1: 'Investeer direct',
      tab1Title: 'Investeer direct',
      tab1Text: `Kies een object en bepaal hoeveel je wilt investeren. Met gemiddeld een extra object per week,
        streeft Bloqify ernaar om het grootste vastgoed aanbod van de Benelux te faciliteren. Je kan een eigen
        portfolio samenstellen die voldoet aan jouw criteria. Daarnaast is het makkelijk om te diversifiëren met
        verschillende soorten vastgoed. Er zijn eenmalige kosten van 2% verbonden aan een investering`,
      tab2: 'Ontvang dividend',
      tab2Title: 'Ontvang dividend',
      tab2Text: `Elk kwartaal ontvang jij dividend op jouw investering. Het dividend is de huurinkomsten minus
        de beheerkosten van het vastgoed`,
      tab3: 'Check je rente',
      tab3Title: 'Check je rente',
      tab3Text: `Check in jouw dashboard de prestatie van je investeringen. Al onze woningen worden elke 12 maanden
        door een RICS gecertificeerde taxateur gewaardeerd. Hierdoor kan jij een inschatting maken van de meerwaarde
        op je investering. De dividendinkomsten worden automatisch meegerekend in het dashboard`,
      tab4: 'Liquiditeit',
      tab4Title: 'Liquiditeit',
      tab4Text1: `Het is mogelijk obligaties op elk moment te verkopen via onze secundaire markt. Verkopen is simpel.
        Zet je obligaties te koop via het handelsplatform. Of als alternatief; wacht voor de 5-jaarlijkse verkoop van het vastgoed,
        waarbij investeerders de overwaarde van het vastgoed zullen ontvangen. De gemiddelde tijd om een obligatie te
        verkopen is 4 dagen`,
      tab4Text2: `Afhankelijk van de gekozen prijs kan het langer of korter duren. Let op: gedurende de introductieperiode
        van de secundaire markt zal het handelsvolume lager zijn dan gemiddeld, dit kan resulteren in een langere verkooptijd`,
      tab5: 'Overhead',
      tab5Title: 'Overhead',
      tab5Text: `Investering in vastgoed is veel werk. Bloqify verandert dat. Papierwerk, advocaten, wanbetalers en reparateurs
        zijn verleden tijd. Bloqify met haar aangesloten vastgoedprojecten regelen dat voor jou, met vele decennia marktervaring.
        Zodat jij kan profiteren van huurinkomsten en waardestijgingen in vastgoed, zonder het gedoe!`,
      tab5TextPoint1: 'Investeringen zoeken',
      tab5TextPoint2: 'Onderhandelingsproces',
      tab5TextPoint3: 'Papierwerk en advocaten',
      tab5TextPoint4: 'Huurders zoeken',
      tab5TextPoint5: 'Onderhoud en reparaties',
      tab5TextPoint6: 'Taxaties',
      tab5TextPoint7: 'Verkoopproces',
      tab6: 'Ons beleid',
      tab6Title: 'Ons beleid',
      tab6Satisfied: 'Tevreden huurders:',
      tab6Text1: `Bloqify gelooft dat tevreden huurders de sleutel is tot toekomstig succes. Onze vastgoedprojecten vragen
        eerlijke huurprijzen en onderhouden het vastgoed grondig, zodat huurders voor langere periodes blijven. Dit betekent
        voor jou stabiele huurinkomsten`,
      tab6SmartPurchases: 'Slimme aankopen:',
      tab6Text2: `Bloqify werkt samen met grote vastgoedprojecten in Nederland. Hierdoor vinden we de beste aankopen, die
        normaal gezien vaak in bezit zijn van grote bedrijven of rijke individuen`,
    },
    investing: {
      investingMadeSimple: 'Simpel investeren',
      selectTheFund: 'Selecteer het project waarin je wilt investeren',
      registerAndIdentify: 'Registreer en identificeer via iDIN',
      buyYourShares: 'Koop je obligaties via iDEAL of SEPA',
      receiveMonth: 'Ontvang dividend per maand',
    },
    stock: {
      amsterdamStockExchange: 'Amsterdam Exchange Index',
      pastPerformance: 'In het verleden behaalde resultaten vormen geen garantie voor de toekomst',
    },
    safeGuards: {
      investmentSafeguards: 'Investerings zekerheden',
      weTakeOurResponsabilities: 'We nemen onze verantwoordelijkheden om je veilig en zonder stress te laten investeren',
      yourInvestmentsAreRingFenced: 'Jouw investeringen zijn veilig',
      andAreSeparated: 'Afgeschermd en gescheiden van de activa en passiva van Bloqhouse',
      compensationScheme: 'Verzekerd',
      protectsTheFunds: 'Jouw investeringen zijn verzekerd via een Stichting Derdengelden',
      financialConductAuthority: 'Autoriteit Financiële Markten',
      isTheBody: 'Het platform is gereguleerd door de Autoriteit Financiële Markten',
    },
    expectations: {
      assetsUnderManagement: 'Vermogen onder beheer',
      capitalReturnedToInvestors: 'Totaal uitgekeerd aan investeerders',
      rentalIncome: 'Huurinkomsten uitgekeerd aan investeerders',
    },
    faq: {
      howDoIIdentify: 'Hoe identificeer ik mijzelf op het platform?',
      forDigital: `Voor het digitaal identificeren van onze private beleggers gebruiken we iDIN,
        een toepassing ontwikkeld door de Nederlandse banken`,
      howIGetDividends: 'Hoe krijg ik mijn dividend uitgekeerd?',
      theDvidendsare: 'Het dividend op het Bloqhouse platform wordt uitgekeerd in de vorm van Euro’s die gestort worden op jouw rekening',
      howCanISell: 'Hoe kan ik mijn investering verkopen?',
      youCanIn2Ways: `Dit kan op twee manieren. (1) Via het handelsplatform kan je ten alle tijde jouw investering te koop zetten.
        (2) Na de looptijd van het project wordt de waarde van het vastgoed naar rato verdeeld over de obligatiehouders op dat moment`,
      whenIsItSold: 'Wanneer wordt het vastgoedproject verkocht / project geliquideerd?',
      theAverage: 'De gemiddelde looptijd van een project is 5 jaar',
      isMyInvestmentInsured: 'Is mijn investering verzekerd tegen ongevallen?',
      yesAllFundsAre: `Ja, al de projecten van onze aangesloten bedrijven zijn volledig verzekerd,
        waardoor je als investeerder hier geen risico voor loopt`,
      howDoIPay: 'Hoe betaal ik mijn obligaties?',
      sharesCanBe: 'obligaties kunnen gekocht worden via iDEAL. Binnenkort zijn ook Bitcoin en PayPal mogelijk',
      whatHappens: 'Wat gebeurt er met mijn investering als het project niet wordt volgeschreven?',
      inThatCase: 'In dat geval wordt jouw investering direct teruggestort op jouw bankrekening',
      upUntillWhichAmount: 'Tot hoeveel Euro kan ik direct online via iDEAL afrekenen?',
      till100k: 'Tot €100.000 kan je direct online afrekenen, anders zal er via SEPA een bank transfer gemaakt moeten worden',
      whoDecides: 'Wie maakt de beslissingen aangaande het project en vastgoed beheer?',
      thatIsBeingCared: 'Dat doen de projectbeheerders',
      questionsAnswers: 'Vragen',
    },
    risks: {
      investmentRisks: 'Let op',
      generalRisksAssociated: `Er zijn algemene risico’s verbonden aan het beleggen in vastgoedprojecten: het marktrisico, risico met betrekking tot
        de vergunningsvereisten, risico stockdividend, rendementsrisico, financierings- en renterisico, verhuur- en leegstandsrisico,
        fiscaal risico, inflatierisico en risico’s samenhangend met de beheerder`,
      theMainRisks: 'Bloqhouse is niet verplicht tot het inkopen van obligaties',
      aDetailedDescription: 'Een uitgebreide beschrijving van de risico’s is te vinden in het prospectus van het gerelateerde project',
    },
    cookies: {
      allowUs: 'Staat u ons toe om {cookiesLink} op te slaan?',
      yourInfoAnalytics: 'Uw informatie zal worden gebruikt voor analyse en website-optimalisatie',
    },
    footer: {
      facebook: 'Facebook',
      twitter: 'Twitter',
      youtube: 'Youtube',
      linkedin: 'Linkedin',
    },
  },
  auth: {
    login: {
      forgotPassword: 'Wachtwoord vergeten?',
      dontHaveAnAccountYet: 'Nog geen account?',
      accessYourBloqify: 'Bloqify dashboard',
      enterYourPassword: 'Vul uw wachtwoord in',
      pleaseActivate: 'We hebben je een e-mailbericht gestuurd met daarin een link om jouw account te activeren',
      userHasRole: 'U heeft geprobeerd in te loggen met een admin account. Gelieve alleen te gebruiken binnen de Bloqadmin omgeving.',
      captchaTimeExpired: 'Captcha verificatie mislukt',
      tryAgain: 'Probeer nogmaals',
      error: 'Something went wrong!',
      descriptionTitle: 'In jouw dashboard kun je',
      descriptionPoint1: 'investeren in duurzame projecten.',
      descriptionPoint2: 'inzicht krijgen in jouw verwachte rendement en uitbetalingen.',
      descriptionPoint3: 'Veilig investeren; jouw persoonsgegevens zijn bij ons altijd goed beschermd.',
    },
    register: {
      becomeAnInvestor: '',
      atLeast10Chars: 'Minimaal 10 tekens',
      alreadyHaveAnAccount: 'Al een account?',
      thankYou: 'Bedankt voor je registratie',
      pleaseCheckEmail: 'Je kunt je account activeren via de e-mail die we je gestuurd hebben',
      itCanTake: 'Dit kan een paar minuten duren',
      youCan: '',
      afterActivating: 'na activatie van je account',
      confirmPassword: 'Bevestig wachtwoord',
    },
    reset: {
      getAPassword: 'Stuur mij een wachtwoord reset link',
      sendResetLink: 'Stuur reset link',
      rememberYourPassword: 'Wachtwoord onthouden?',
      emailSent: 'Als het ingevoerde emailadres bij ons bekend is, heb je een e-mail ontvangen',
      pleaseCheckInbox: 'Klik op de link in de e-mail om je wachtwoord te resetten',
      expiredError: 'Uit veiligheidsoverwegingen was deze link maar 24 uur geldig. Vul hier uw e-mailadres in om een nieuwe e-mail te versturen, volg die link en maak het wachtwoord aan.',
      reactivate: 'Heractiveer jouw Corekees account',
    },
    verification: {
      newPassword: 'Nieuw wachtwoord',
      confirmPassword: 'Wachtwoord bevestigen',
      emailVerification: 'E-mail verificatie',
      resetPassword: 'Reset wachtwoord',
      claimPasswoord: 'Claim uw wachtwoord',
      changeEmail: 'Verandering e-mail',
      verifyingSuccess: 'Alles is ingesteld, je kunt nu inloggen',
      verifyingError: 'Er is iets fout gegaan! Registreer opnieuw',
      changeError: 'Er is iets fout gegaan! Probeer het opnieuw',
      changeSuccess: 'Alles is ingesteld, je kunt nu inloggen',
      passwordLenght: 'Wachtwoord moet 10 of langer zijn',
      changeDataRequestConfirmation: 'Weet je zeker dat je je <strong>{type}</strong> van <em>{oldValue}</em> naar <em>{newValue}</em> wilt veranderen',
      revertSecondFactor: 'U heeft 2-factor authenticatie geactiveerd, op deze pagina kunt u dit ongedaan maken',
      revertSecondFactorSuccess: 'U heeft 2-factor authenticatie succesvol gedeactiveerd',
    },
  },
  dashboard: {
    infobox: {
      upgrade: 'Verfieer account',
      dismiss: 'Sluit bericht',
      notNow: 'Niet nu',
      pending: 'Jouw account wordt geverifieerd',
      pendingDescription: '{fundManager} neemt contact met je op',
      youCanInvest: 'In de tussentijd kun je gewoon investeren',
      youNeedApproval: 'Pas na verificatie van jouw account kan je beleggen',
      upgradeTo: 'Maak je profiel compleet',
      basicUser: 'We hebben nog wat gegevens van je nodig',
      payments: {
        openPayment: 'De betaling voor {shareCount} obligaties van {assetName} is nog in behandeling.',
        sepaPayment: 'Het kan enkele dagen duren voordat een SEPA-transactie is verwerkt. Als je deze de bank overschrijving reeds hebt geïnitieerd, dan hoef je niks meer te doen.',
        makePayment: 'Voldoe betaling',
        openPayments: 'Er zijn {pendingPayments} betalingen die nog open staan',
        sepaPayments: 'Het kan enkele dagen duren voordat een SEPA-transactie is verwerkt. Als je deze de bank overschrijving reeds hebt geïnitieerd, dan hoef je niks meer te doen',
        makePayments: 'Zie openstaande betalingen',
        pendingPayments: 'Openstaande betalingen',
      },
    },
    balance: {
      totalBalance: 'Mijn investeringen',
      onTheseFunds: 'Geïnvesteerd in',
      annualStatement: 'Jaaroverzicht',
    },
    dividends: {
      onTheseFunds: 'Ontvangen uit',
      comingSoon: 'Binnenkort worden hier uw ontvangen rentes getoond',
    },
    funds: {
      onBoard: 'In portfolio',
      notOnBoard: 'Niet in portfolio',
      totalValue: 'Projectomvang',
      myInvestment: 'Mijn investering',
      totalEarnings: 'in totaal',
      netDividendYield: 'Rente',
      netDividendYieldMine: 'Mijn uitgekeerde betalingen',
      startToInvest: 'Start met beleggen in vastgoed',
      investInOtherFunds: 'Investeer ook in rest van de projecten om meer dividend te ontvangen',
      investMoreOnSingleFund: 'Bekijk het vastgoed aanbod',
      showAlltheFunds: 'Toon alle projecten',
      myPortfolio: 'Mijn Dashboard',
      myFunds: 'Mijn investeringen',
      otherFunds: 'Overige projecten',
      myFundsPlaceholder: 'Hier worden de projecten getoond waarin je hebt geïnvesteerd',
      myFundsStartInvest: 'Investeer in jouw eerste project',
      progress: 'Project vooruitgang',
      more: 'meer',
      premium: 'Dit is een Besloten project, log in om deze sectie te zien',
      premiumLabel: 'Besloten projecten',
      premiumFund: 'Besloten project',
    },
    investments: {
      showPayments: 'Toon transacties',
      hidePayments: 'Verberg transacties',
      loadAllInvestments: 'Alle investeringen',
      noPayments: 'Er zijn nog geen transacties',
      requestSupport: 'Neem contact met ons op',
      showFewerPayments: 'Minder transacties',
      showAllPayments: 'Meer transacties',
      active: 'actief',
      ended: 'uitgestapt',
      started: 'begonnen',
    },
    support: {
      technical: 'Technisch',
      technicalQuestion: 'Technische vraag',
      fundSpecificQuestion: 'project specifieke vraag',
      aboutTheFund: 'Over het project',
      sendMessage: 'Verzenden',
      messageSent: 'Je bericht is verzonden',
      youWillBeAnswered: 'Wij zullen je z.s.m. per e-mailbericht beantwoorden',
    },
    errors: {
      pdfError: 'Er is een fout opgetreden bij het ophalen van het document. Probeer het later opnieuw of neem contact op met ondersteuning',
      noInvestmentsError: 'Er zijn geen investeringen voor het geselecteerde jaar',
      giftCodeIncorrect: 'Deze cadeaucode is onjuist.',
      giftCodeAlreadyRedeemed: 'Deze cadeaucode is al verzilverd.',
      giftCodeExpired: 'Deze cadeaucode is verlopen.',
    },
    table: {
      count: '{from}-{to} van {count} regels vertoond|{count} regels|Één regel',
      first: 'Eerste',
      last: 'Laatste',
      filter: 'Filter:',
      filterPlaceholder: 'Zoeken',
      limit: 'Regels:',
      page: 'Pagina:',
      noResults: 'Er is nog geen rendement uitgekeerd.',
      filterBy: 'Filter op {column}',
      loading: 'Loading...',
      defaultOption: 'Selecteer {column}',
      columns: 'Kolommen',
      progress: {
        noResults: 'Je hebt nog geen investering gedaan.',
      },
      payments: {
        noResults: 'Je hebt nog geen betalingen gedaan.',
      },
      bonds: {
        noResults: 'Je hebt nog geen obligaties.',
      },
      earnings: {
        noResults: 'Je hebt nog geen uitbetalingen ontvangen.',
      },
    },
  },
  settings: {
    details: {
      firstAndLastName: 'Voor en achternaam',
      changePassword: 'Verander wachtwoord',
      changeEmail: 'Verander e-mailadres',
      newPasswordOptional: 'Nieuw wachtwoord',
      newPasswordOptionalRepeat: 'Herhaal nieuw wachtwoord',
      confirmWithCurrentPassword: 'Bevestig met huidig wachtwoord',
      personalDetails: 'Persoonlijke gegevens',
      leaveEmpty: 'Laat leeg als je je wachtwoord niet wilt veranderen',
      useAtLeastTen: 'Gebruik ten minste 10 tekens om je account veilig te houden',
      pleaseConfirm: 'Voer eerst het oude wachtwoord in om het wachtwoord te wijzigen',
      currentPassword: 'Huidig wachtwoord',
      newEmail: 'Nieuwe e-mail',
      passwordToChangeMail: 'Typ jouw huidige wachtwoord om jouw e-mailadres te wijzigen',
      changeHere: 'Dit is het huidige bij ons bekende IBAN nummer, wijzig indien nodig en klik op opslaan',
      mailChanged: 'Uw e-mailadres is gewijzigd. Controleer uw e-mail om het nieuwe e-mailadres te activeren en opnieuw in te loggen',
      bankAccountChanged: 'Jouw IBAN is succesvol aangepast',
      passwordChanged: 'Je wachtwoord is gewijzigd. Log alsjeblieft nogmaals in',
      nameUpdated: 'Uw naam is bijgewerkt',
      linkSent: 'Open de link die we zojuist naar uw e-mail hebben gestuurd om uw wijziging van bankrekeningnummer te bevestigen',
      identity: 'Persoonlijke Informatie',
      credentials: 'Geloofsbrieven',
    },
    identification: {
      identification: 'Identificatie',
      complete: 'Verifieer account',
      support: 'Support',
      title: 'Voltooi deze stappen om volledig toegang te krijgen',
      userIdentification: 'Account aanmaken',
      registeredToPlatform: 'Je hebt een account aangemaakt',
      investorIdentification: 'Verifieer jouw account',
      completeStep: 'Voltooi deze stap en begin met investeren',
      success: 'Jouw account is geverifieerd',
      rejected: 'Jouw identificatieverzoek is afgewezen',
    },
    tier: {
      currentTier: 'Niet van toepassing',
      cannotRequestTier: 'Je kan op dit moment niet jouw tier level inzien of wijzigen',
      workingOnFunctionality: 'We werken hard aan deze functionaliteit',
    },
  },
  help: {
    baseKnowledge: {
      baseKnowledge: 'Kennisbank',
      rics: 'RICS',
      aex: 'AEX',
      afm: 'AFM',
    },
    faq: {
      faq: 'FAQs',
      isIdinSecure: 'Is iDIN veilig?',
      howPersonalInformation: 'Hoe wordt er omgegaan met mijn persoonsgegevens?',
      howInvestDutch: 'Hoe kan ik investeren als ik geen Nederlandse bankrekening heb?',
      howInvestCompany: 'Hoe kan ik investeren vanuit mijn bedrijf?',
      iDINAdheres: 'iDIN conformeert zich aan de Europese jurisdictie',
      contactOnUpdates: 'We nemen alleen contact met je op omtrent belangrijke updates over je account en investeringen',
      pleaseSend: 'Stuur alsjeblieft een',
      manualRequest: 'investerings aanvraag',
      howDoIIdentify: 'Hoe identificeer ik mijzelf op het platform?',
      forDigital: `Voor het digitaal identificeren van onze private beleggers gebruiken we iDIN,
        een toepassing ontwikkeld door de Nederlandse banken`,
      howIGetDividends: 'Hoe krijg ik mijn dividend uitgekeerd?',
      theDvidendsare: 'Het dividend op het Bloqhouse platform wordt uitgekeerd in de vorm van Euro’s die gestort worden op jouw rekening',
      howCanISell: 'Hoe kan ik mijn investering verkopen?',
      youCanIn2Ways: `Dit kan op twee manieren. (1) Via het handelsplatform kan je ten alle tijde jouw investering te koop zetten.
        (2) Na de looptijd van het project wordt de waarde van het vastgoed naar rato verdeeld over de obligatiehouders op dat moment`,
      whenIsItSold: 'Wanneer wordt het vastgoedproject verkocht / project geliquideerd?',
      theAverage: 'De gemiddelde looptijd van een project is 5 jaar',
      isMyInvestmentInsured: 'Is mijn investering verzekerd tegen ongevallen?',
      yesAllFundsAre: `Ja, al de projecten van onze aangesloten bedrijven zijn volledig verzekerd,
        waardoor je als investeerder hier geen risico voor loopt`,
      howDoIPay: 'Hoe betaal ik mijn obligaties?',
      sharesCanBe: 'obligaties kunnen gekocht worden via iDEAL. Binnenkort zijn ook Bitcoin en PayPal mogelijk',
      whatHappens: 'Wat gebeurt er met mijn investering als het project niet wordt volgeschreven?',
      inThatCase: 'In dat geval wordt jouw investering direct teruggestort op jouw bankrekening',
      upUntillWhichAmount: 'Tot hoeveel Euro kan ik direct online via iDEAL afrekenen?',
      till100k: 'Tot €100.000 kan je direct online afrekenen, anders zal er via SEPA een bank transfer gemaakt moeten worden',
      whoDecides: 'Wie maakt de beslissingen aangaande het project en vastgoed beheer?',
      thatIsBeingCared: 'Dat doen de projectbeheerders',
    },
  },
  checkout: {
    fund: {
      selectAnAmount: 'investeringsbedrag',
      moreInfo: 'meer info',
      emissionPricePerShare: 'Prijs per obligatie',
      totalFundSize: 'Totale project grootte',
      emissionCosts: 'Emissie kosten',
      grossDividendYield: 'Bruto rente',
      netDividendYield: 'Rente',
      verifyYourIdentity: 'identificeer mij',
      fundDocs: 'documentatie',
      noFilesFound: 'geen docs gevonden',
    },
    investment: {
      selectTheAmountTrees: 'Selecteer het aantal bomen',
      selectTheAmountBonds: 'Selecteer het aantal obligaties',
      selectPaymentOption: 'Type investering',
      selectPaymentMethod: 'Selecteer betalings methode',
      referralCode: 'Promotie-/referralcode',
      couponCode: 'Referral Code',
      referralCodeSuccess: 'Je hebt een geldige referral code ingevoerd',
      couponCodeSuccess: 'Je hebt een geldige code ingevoerd',
      codeInvalid: 'Code is ongeldig. Voer een geldige code in',
      singlePayment: 'eenmalige investering',
      giftPurchase: 'bomen als cadeau',
      recurringPayment: 'Abonnement',
      ideal: 'iDEAL',
      sepa: 'SEPA',
      bancontact: 'BanContact',
      sofort: 'SOFORT',
      amountIn: 'Bedrag in',
      amountPlaceholder: 'Aantal',
      investmentCosts: 'Investering',
      emissionCosts: 'Emissiekosten',
      discount: 'korting',
      totalCosts: 'Totale investering',
      maximumInvestment: 'Maximale investering met jouw account',
      placeOrder: 'plaats order',
      startThePayment: 'start de betaling',
      legalTerms: 'Bevestigen',
      finalize: 'Naar investeringsvoorwaarden',
      selectAnOption: 'selecteer een optie',
      dividends: 'selecteer rente',
      availableFund: 'De voortgangsbalk geeft de beschikbaarheid van het project weer',
      acceptAndContinue: 'Akkoord & Doorgaan',
      questionnaire: 'Ga door naar de volgende stap',
      giftPurchaseNote: 'Let op: dit is het aantal bomen dat je op één cadeaukaart zet. Meerdere mensen bomen geven? Doe een nieuwe bestelling voor een losse cadeaukaart.',
    },
    questionnaire: {
      questionnaire: 'Vragenlijst',
      mandatory: 'Alle invulvelden zijn verplicht',
      errorLoadingQuestions: 'Er was een probleem tijdens het laden van de vragenlijst',
    },
    legal: {
      accept: 'Accepteer de voorwaarden',
      continue: 'Ga door naar betaling',
      legalAgreement: 'Investeringsvoorwaarden',
      paymentRecap: 'Betalingsoverzicht',
      finalisingPayment: 'Voltooi de betaling',
      interestRate: 'Rente',
      byThisField: 'Door dit veld te accepteren geef ik aan het onderstaande te begrijpen en toe te staan:',
      gtac: 'Algemene voorwaarden',
      bondConditions: 'Obligatievoorwaarden',
      avgPolicy: 'AVG Beleid',
      point1: 'Ik investeer in een obligatie met variabele rente. Ik begrijp dat mijn rente lager kan zijn dan verwacht, en dat ik mijn initiële investering kan verliezen.',
      point2: 'Ik ga akkoord met de {0}.',
      point3: 'Ik ga akkoord met de {0}.',
      point4: 'Ik begrijp dat Stichting Corekees mijn gegevens verzamelt en opslaat conform het {0}.',
      point5: 'Ik geef Stichting Corekees toestemming om maandelijks mijn abonnementsgeld af te schrijven van mijn rekening via SEPA Direct Debit tot wederopzegging.',
      point6: 'Ik ben ouder dan 18 jaar en geen staatsburger van de Verenigde Staten.',
    },
    manualOrder: {
      requestForInvestment: 'Vraag investering aan',
      amountToInvestIn: 'Bedrag ter investering',
      sendRequest: 'Stuur aanvraag',
      youCanAlsoContact: 'Je kan ook contact opnemen met ons via e-mail of telefoon',
      weWillGetYouBack: 'Je wordt beantwoord binnen 48 uur',
    },
    status: {
      paymentCompleted: 'Betaling succesvol',
      paymentCanceled: 'Betaling mislukt',
      paymentFailed: 'Betaling mislukt',
      paymentPending: 'Betaling in afwachting',
      paymentNotFound: 'Betaling niet gevonden',
      paymentProcessing: 'Betaling wordt verwerkt',
      youBought: 'Je kocht <strong>{shares} obligatie</strong> voor <strong>€ {euro}</strong> | Je kocht <strong>{shares} obligaties</strong> voor <strong>€ {euro}</strong>',
      youTriedToBuy: 'Je probeerderde <strong>{shares} obligaties</strong> te kopen voor € <strong>{euro}</strong>',
      paymentCanceledDescription: 'De betaling is niet gelukt. Neem contact met ons op als dit probleem zich voor blijft doen',
      paymentPendingDescription: 'We verwerken je betaling, je ontvangt een update wanneer de betaling is voltooid.',
      tryAgain: 'probeer nogmaals',
      accountInvestAgain: 'Bekijk andere projecten',
      backToThePortfolio: 'Ga naar het dashboard',
      congratulations: 'Gefeliciteerd',
      open: {
        makePayment: 'Voltooi de betaling',
        banktransfer: {
          header: 'We wachten op jouw bankoverschrijving',
          info: 'Het kan enkele dagen duren voordat een SEPA-transactie is verwerkt. Als je deze de bank overschrijving reeds hebt geïnitieerd, dan hoef je niks meer te doen.',
        },
        other: {
          header: 'We wachten op jouw betaling',
          info: 'Jouw betaling staat nog open, kan je de betaling alsnog voldoen?',
        },
      },
    },
    unlimited: {
      investMoreThan: 'Investeer meer dan',
      ourProvider: 'Onze payment provider kan momenteeel je transactie niet verwerken',
      toInvestMore: 'Om meer te investeren, stuur ons een aanvraag of betaal in meerdere stappen',
      startRequest: 'Start investerings aanvraag',
      youCanEnter: 'Verstrek ons je gegevens en het gewenste bedrag ter investering',
      managerContacts: 'Beheer contacten',
    },
    errors: {
      notEnoughPermissions: 'Onvoldoende rechten met deze referenties',
      somethingWentWrong: 'Je verificatie is nog niet voltooid',
      somethingIsMissing: 'Er mist iets',
      somethingIsNotWorking: 'Er gaat iets mis',
      problemPersist: 'Neem contact met ons op als het probleem zich blijft voordoen',
      error404: 'Fout 404 - Pagina niet gevonden',
      backToHomepage: 'Terug naar het dashboard',
    },
    redirect: {
      text: 'De betaling wordt gegenereerd via Pay.nl, onze betaaldienstverlener...',
    },
    corekees: {
      trees: {
        title: 'Investeer in project Pongamia',
        eenmaligInvesteren: 'Eenmalig investeren',
        eenmaligInvesterenText: `Plant bomen die actief CO₂ uit de lucht halen en groene brandstof produceren,
        en behaal een verwacht rendement van 7,8% tot 8,5% per boom, per jaar.`,
        maandelijksInvesteren: 'Maandelijks investeren met het Boom-spaar-abonnement',
        maandelijksInvesterenText: `Plant elke maand een door jou bepaald aantal bomen. Het abonnement wordt
        maandelijks via automatische incasso afgeschreven en is na de eerste drie maanden blijvend maandelijks
        opzegbaar. Daarnaast heeft deze vorm van beleggen nog een aantal voordelen:`,
        maandelijksInvesterenListOne: 'Laagdrempelig  - Je kunt al investeren vanaf slechts €22,50 per maand.',
        maandelijksInvesterenListTwo: `Risicospreiding - Door niet al je bomen in één keer aan te schaffen
        verkleinen we het agrarische risico.`,
        maandelijksInvesterenListThree: `Staffelkorting - Hoe meer bomen per maand, hoe hoger je rendement uitvalt
        oplopend tot 8,5% bij 10 bomen per maand.`,
        giftPurchase: 'Bomen cadeau doen',
        giftPurchaseText: `Geef een groene toekomst cadeau. Koop een boom-cadeaukaart en geef Corekees bomen cadeau.
        Let op: cadeaubomen zijn eenmalig (dus geen abonnementen) en het aantal geselecteerde bomen is de waarde van de boom-cadeaukaart voor één ontvanger.
        Als jij dus bomen aan verschillende mensen wilt geven, zul je meerdere losse boom-cadeaukaarten moeten kopen. Dit kan simpelweg door opnieuw op ‘Investeren’ te klikken in jouw dashboard.`,
      },
      gras: {
        title: 'Investeer in project Olifantsgras',
        par1title: 'Een eenmalige crowdfunding campagne',
        // eslint-disable-next-line
        par1text: 'Project Olifantsgras is een eenmalige campagne voor het planten van 12 hectare Olifantsgras in Kenia. Eén obligatie kost €65,- en staat gelijk aan 50 m2 Olifantsgras. Er zijn slechts 2391 Obligaties beschikbaar. Op = Op, dus wacht niet te lang met beslissen! De obligaties leveren naast rendement ook een geschatte CO2 compensatie van 55 kg jaar op! Daarnaast heeft deze vorm van beleggen nog een aantal voordelen:',
        listItem1: 'Laagdrempelig - Je kunt al investeren voor slechts €65,-',
        listItem2: 'Korte looptijd - De obligatie is na 5 jaar volledig afgelost',
        listItem3: 'Geografische spreiding - Een mooie aanvulling op je obligaties in Project Pongamia',
        listItem4: 'Aantrekkelijk Rendement - een verwacht rendement van gemiddeld 8,5% per jaar',
        par2title: 'Olifantsgras',
        // eslint-disable-next-line
        par2text: 'Olifantsgras gras groeit supersnel, wordt heel erg hoog, en kan meerdere keren per jaar geoogst worden. Van het geoogste gras maken maken we biobrandstof-briketten die we verkopen aan lokale fabrikanten. Zo creëren we een duurzaam alternatief voor brandhout of fossiele brandstof, voorkomen we ontbossing en dragen we bij aan de Energietransitie.',
      },
      bamboo: {
        title: 'Investeer in project Bamboe',
        par1title: 'Een eenmalige investeringscampagne',
        // eslint-disable-next-line
        par1text: 'Met dit project investeer je in Europese bamboebossen. Bamboe groeit 15 keer keer sneller dan hout, absorbeert grote hoeveelheden CO₂ en is een biologisch afbreekbaar product met ruim 10.000 verschillende toepassingsmogelijkheden. De geoogste bamboe verkopen we aan Europese bedrijven die het verwerken in tal van producten. Zo dragen we bij aan de ontwikkeling van een nieuwe biobased industrie in Europa en zorgen we voor vergroening van ons continent.',
        listItem1: 'Europees project - Samenwerking met een Nederlands bedrijf in Portugal',
        listItem2: 'Aantrekkelijk Rendement - Een verwacht rendement van gemiddeld 6,7% per jaar',
        listItem3: 'Gecertificeerde CO₂ verwijdering - Carbon credits zijn uitgegeven door ONCRA',
        listItem4: 'Geografische spreiding - Een mooie aanvulling op je investeringen in Pongamia of Olifantsgras',
      },
    },
  },
  identification: {
    privateInvestor: 'Particuliere investeerder',
    selectAnOption: 'Maak een keuze',
    dutchBankAccount: 'Investeren als natuurlijk persoon met NL bankrekening',
    noDutchBankAccount: 'Investeren als natuurlijk persoon',
    businessTitle: 'Zakelijke investeerder',
    businessInvestment: 'Investeren vanuit een bedrijf',
    questionnaire: {
      intro: 'Alvorens het verifiëren van jouw identiteit, graag de volgende vragen beantwoorden',
      submit: 'Bevestig de antwoorden',
      move: 'Ga naar de volgende stap',
    },
    business: {
      fillFieldsCompany: 'Bedrijfsinformatie',
      kvkNumber: 'KvK nummer',
      uploadYourKvKImage: 'Upload je KvK uittreksel',
      submitYourData: 'Verzend gegevens',
      orContactUs: 'Contact ons',
      youCanAlso: 'Je kan ook contact opnemen met ons via e-mail of telefoon',
      weWillGetBackToYou: 'Je wordt beantwoord binnen 48 uur',
    },
    idin: {
      selectAnAmount: 'Ga door naar het bedrag kiezen',
      identityVerification: 'Identificatie',
      pleaseVerify: 'Identificeer jezelf met behulp van',
      verificationService: 'een identificatie service ondersteund door de Nederlandse banken',
      completedIdin: 'Je iDIN identificatie is voltooid',
      pleaseWait: 'Een momentje, we zoeken contact met iDIN',
      pleaseSelectAnOption: 'Selecteer een optie',
      selectBank: 'Selecteer je bank',
      thereWasAnError: 'Er is iets misgegaan met iDIN',
      pleaseContactSupport: 'Contact onze helpdesk alsjeblieft',
      whatIsIdin: 'Uitleg iDIN',
      idinExplanation: `iDIN is een Nederlands online identificatiemiddel. Particulieren kunnen zich met behulp van iDIN identificeren bij een bedrijf zoals {companyName}.
        De persoon logt in bij zijn of haar bank die dan bepaalde gegevens van de persoon, die de bank zelf al gecontroleerd heeft, doorgeeft aan het bedrijf.
        Alle klanten van de bank die al volledig door de bank zijn geïdentificeerd kunnen gebruik maken van iDIN.`,
      idinError: 'Er is een fout opgetreden met de iDIN-service. Probeer het opnieuw of neem contact op met de ondersteuning',
    },
    world: {
      fillWithInfo: 'Persoonlijke informatie',
      uploadYourPassport: `Upload je identiteitsbewijs (paspoort, identiteitskaart of rijbewijs).
       Bij de ID upload moet de volgende informatie zichtbaar zijn: Naam, Geboortedatum, Vervaldatum, nationaliteit en Echtheidskenmerk (watermerk)`,
      submitYourData: 'Verzend gegevens',
    },
    FormStatus: {
      requestSent: 'Aanvraag verzonden!',
      weAreCheckingYourData: 'We zijn je account aan het verifiëren. In de tussentijd kun je gewoon investeren.',
      youAreVerified: 'Je bent geverifieerd en klaar om te investeren!',
      exploreProperties: 'Bekijk het vastgoed',
      requestRejected: 'Verzoek afgewezen!',
      weRejectedYourRequest: 'Omdat je account niet voldoet aan onze verificatie-eisen, kun je geen investering doen. Neem contact met ons op voor meer informatie.',
      redirect: 'Ga terug naar het dashboard',
      proceedInvestment: 'Ga verder met investeren',
    },
  },
  properties: {
    exploreProperty: 'Meer details',
    startDate: 'Gestart op',
    noResults: 'We konden geen enkele woning met deze naam vinden, probeer het opnieuw',
  },
  property: {
    propertyInfo: {
      investMore: 'Investeer meer',
      investOnThisFund: 'Investeer in dit project',
    },
    propertyContent: {
      progress: 'Voortgang inschrijvingen',
      investmentCase: 'Samenvatting',
      propertyDetails: 'Beschrijving vastgoed',
      additionalInfo: 'Extra informatie',
    },
    reservation: {
      title: 'Toon interesse in object',
      confirm: 'Vul de verplichte velden in om uw interesse in <strong>{naam}</strong> te tonen.',
      error: 'Er ging iets verkeerd.',
      success: 'Reservering succesvol verstuurd!',
    },
  },
  giftCode: {
    title: 'Verzilver jouw cadeaucode',
    description: 'Vul hieronder jouw cadeaucode in om de boom/bomen bij te schrijven op je account.',
    descriptionNoInvestor: 'Je moet eerst je registratie voltooien voordat je een cadeaucode kunt verzilveren.',
    agreement: {
      description: 'Door dit veld te accepteren:',
      point1: 'Ga ik akkoord met de <a href="https://corekees.com/wp-content/uploads/2021/05/20210605-Algemene_Voorwaarden_Stichting_Corekees.pdf" target="_blank">Algemene voorwaarden</a> en <a href="https://corekees.com/wp-content/uploads/2021/05/20210506-Obligatievoorwaarden_Stichting_Corekees.pdf" target="_blank">Obligatievoorwaarden</a>.',
      point2: 'Ga ik akkoord dat Stichting Corekees mijn gegevens verzamelt en opslaat conform het <a href="https://corekees.com/wp-content/uploads/2020/09/The_Corekees_Foundation_GDPR_Policy.pdf" target="_blank">AVG Beleid</a>.',
      point3: 'Verklaar ik dat ik minstens 18 jaar of ouder en geen staatsburger van de Verenigde Staten ben.',
    },
    redeem: {
      success: 'Cadeaucode succesvol ingewisseld!',
    },
  },
  referralCode: {
    title: 'Deel je referral code',
    description: `Wanneer iemand jouw unieke code gebruikt bij het afsluiten van een investering, krijgt diegene €50,- korting.
                  Jij ontvangt een €50,- kortingscode die je kunt gebruiken bij een volgende investering.
                  Deze actie is oneindig geldig: hoe meer vrienden je introduceert, hoe meer kortingscodes je ontvangt.`,
    friend: 'Vriend',
    friends: 'Vrienden',
    credit: 'Krediet',
    you: 'Jij',
    friendBenefit: 'Investeer met €50 korting',
    youBenefit: 'Krijgt €50,- korting op je<br>eerstvolgende bestelling',
    referredFriends: 'Aantal vrienden die via jou<br>aan het sparen zijn.',
    referredCredit: 'Totaal krediet dat andere vrienden via jou hebben veiliggesteld.',
    copySuccess: 'Referral code succesvol gekopieerd naar klembord',
    termsAndConditions: 'Lees de actievoorwaarden',
  },
  tooltip: {
    amountOwned: 'Het totaal aantal obligaties dat jij in je bezit hebt',
    amountGet: 'Totaal gerealiseerde (aflossing + rendement) betalingen in euro’s van jouw obligaties',
    amountCompensation: 'Het totale aantal kg CO₂ dat jij per jaar compenseert',
    fundSize: 'Totale waarde van je projecten',
    availability: 'Beschikbaar aantal obligaties',
    netDividendYieldMine: 'Het Euros dat u per jaar ontvangt',
    netDividendYield: 'De rente die u per jaar ontvangt',
    yourInvestment: 'Het totale bedrag dat u in dit project heeft belegd',
    durationMsg: 'Looptijd van het project',
    interestMsg: 'Verwacht rendement per jaar',
    compensationMsg: 'Aantal kg CO2 compensatie per jaar',
    yourInterestRate: 'Totale rente die u heeft ontvangen',
    emissionCosts: 'De vergoeding die wordt gerekend om een ​​obligatie te kopen',
    fundProgress: 'Percentage van het project dat is volgeschreven',
    discount: 'Promotiecode en/of staffelkorting',
    /* eslint-disable max-len */
    promotionCode: 'Vul hier je promotiecode of referralcode in. Deze zijn alleen geldig bij eenmalige investeringen. Een cadeaucode kun je valideren bij het desbetreffende project in het project-overzicht.',
    onlinePayment: 'Deze betaling is via het platform uitgevoerd',
    offlinePayment: 'Deze betaling is buiten het platform uitgevoerd',
    downloadStatement: 'Selecteer het jaar aan de linkerkant en klik om het jaaroverzicht naar keuze te downloaden',
    initialLogin: 'Klik hier om je dashboard en je investeringsoverzicht te zien',
    remainingDesc: 'Resterend aantal obligaties in dit project tot klimaatneutraal',
    emissionDesc: 'Jaarlijks gecompenseerde CO₂ uitstoot',
  },
  gdpr: {
    bar: {
      title: 'Control the collection and use of your personal data',
      textMain: `With your consent, our partners and we use cookies or equivalents to collect, store and use some of
      your personal data, such as your visit on this website. They are used to analyse audiences, offer personalised content
      and / or targeted advertising campaigns, improve customer knowledge, optimize services and fight fraud.
      Click on the “Accept all” button to consent to these uses or click on “Set your options” to obtain more details
      and / or reject all or part of them.`,
      textWarning: `Warning: By continuing your browsing on this site, you accept these uses. You can change your
      mind at any time.`,
      setOptions: 'Set your options',
      acceptEverything: 'Accept everything',
    },
    modal: {
      headerTitle: 'Onze Data Collection Policy',
      /* eslint-disable max-len */
      headerText: '{companyName} maakt op deze site gebruik van cookies. Een cookie is een klein stukje informatie dat in de vorm van een bestand op uw computer of mobiele apparaat wordt opgeslagen. Hierdoor herkennen wij uw computer of mobiele apparaat bij een volgend bezoek.',
      headerTitle2: 'Waarom wij cookies plaatsen',
      headerText2: 'Wij plaatsen cookies om onze site goed te laten werken en te beveiligen. En om onze site, producten en diensten te analyseren en te verbeteren. Door cookies herkennen we u bij een volgend bezoek. Telefoonnummers en e-mailadressen worden niet opgeslagen in cookies. U geeft toestemming voor cookies door op Accepteer te klikken.\n\nWij plaatsen verschillende soorten cookies\nWe kennen vier soorten cookies: Functionele Cookies, Analytische Cookies, Advertentie en personalisatiecookies. We leggen u graag uit op welke manier wij deze verschillende soorten cookies gebruiken.',
      bodyButtons: 'Selecteer of deselecteer welke gegevens u met ons wilt delen',
      bodyButtonsDeselect: 'Alles deselecteren',
      bodyButtonsSelect: 'Alles selecteren',
      bodySeeMore: 'Meer info',
      /* eslint-enable max-len */
    },
  },
  /**
   * Meta section
   */
  meta: {
    checkout: {
      title: 'Uitchecken',
    },
    dashboard: {
      title: 'Portfolio',
    },
    details: {
      title: 'Gegevens',
    },
    faq: {
      title: 'FAQs',
    },
    glossary: {
      title: 'Woordenlijst',
    },
    identification: {
      title: 'Identificatie',
    },
    knowledge: {
      title: 'Kennisbank',
    },
    landing: {
      description: 'De beschrijving van de Landing Page',
    },
    login: {
      title: 'Log in',
      description: 'Log in op uw account om te investeren in onze projecten.',
    },
    properties: {
      title: 'Projecten',
      description: 'Blader door onze projecten en ontdek hier meer over hen.',
    },
    property: {
      description: 'Investeer nu in {name}. Deze woning bevindt zich op {address}. U kunt beginnen met beleggen vanaf {min} euros.',
    },
    register: {
      title: 'Registreer',
      description: 'Meld je aan voor {name} en ontdek onze projecten. Registreer met uw e-mail om te beginnen met beleggen.',
    },
    reset: {
      title: 'Reset wachtwoord',
    },
    settings: {
      title: 'Instellingen',
    },
    verification: {
      title: 'Registratie',
    },
  },
};
export default {
  nl: mergeWithBrandLangFile(original, 'nl'),
};
